@include media-breakpoint-down(sm){
  .hero {
    .desktop {
      display: none;
    }
    .Paragraph {
      display: none;
    }
  }

  .description {
    > .container-images {
      .image-small {
        width: 100px;
      }
    }
    h4 {
      font-size: 28px;
    }
    p{
      font-family: 'Gill Sans', sans-serif;
      font-size: 15px;
    }
  }
}
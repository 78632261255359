$header-height: 54px;
$header-height-md: 100px;

$hero-bg-width-md: 50vw;
$hero-bg-width-lg: 100%;
$hero-bg-width-xl: 100%;

// Colors
$body-color: #111;
$dark: #000;
$white: #fff;
$davys-grey: #5e5e5e;
$sonic-silver: #777;
$raisin-black: #2c2e3f;
$sizzling-red: #f05d5e;
$skobeloff: #046e71;
$yale-blue: #1f4e91;
$raisin-black-opacity: rgba(44, 46, 63, 0.7);

$primary: $skobeloff;
$secondary: $yale-blue;
$gray: $davys-grey;

// Font weight
$font-weight-normal: normal;
$font-weight-bold: bold;
$font-weight-light: 300;
$font-weight-medium: 500;
$font-weight-black: 900;

// Font size
$font-size-xs: ($font-size-base * 0.75);

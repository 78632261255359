@include media-breakpoint-up(lg) {
  .hero {
    height: 100vh;
    min-height: 50rem;
    padding-bottom: 70px;

    > .container {
      width: 100%;
      max-width: 100%;
      height: 100%;

      > .row {
        height: 100%;

        > [class*="col"] {
          &:last-child {
            padding-left: 3rem;
          }
        }
      }
    }
  }

  .hero-content {
    height: 95%;
    position: relative;

    > div {
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }

  .hero-bg {
    width: $hero-bg-width-lg;
    height: 96%;
  }
}

.form {
}

.form-group {
  label {
    &:not(.form-check-label) {
      font-weight: $font-weight-bold;
    }
  }
}

.form-check-input {
  margin-top: 0.18rem;
}

.form-control {
  border-radius: 0;
}

.form-control--border-radius {
  border-radius: 0.5rem;
}

.form-control--transparent {
  background-color: transparent;

  &:hover,
  &:active,
  &::placeholder {
    color: $white;
    background-color: transparent;
    border-color: $white;
  }

  &:focus {
    color: $white;
    background-color: transparent;
    border-color: $white;
    box-shadow: 0 0 0 0.2rem #ffffff40;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-error-message {
  font-size: $font-size-sm;
  font-weight: $font-weight-medium;
}

.form-error-message--red {
  color: $sizzling-red;
}

.form-error-message--white {
  color: $white;
}
